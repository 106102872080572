import React from "react";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import CTA from "../components/cta";
import SnapFingersIcon from "../icons/snap.svg";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import FeaturedOntechGraphic from "../images/partnerships-shopify/featured-ontech.svg";
import FeaturedOpenfitGraphic from "../images/partnerships-shopify/featured-openfit.svg";
import shopifyPlusLogo from "../images/partners/shopify-plus.png";
import shopifyPlusWhiteLogo from "../images/partners/shopify-plus-white.png";

const Index = ({ location }) => {
  const breakpoints = useBreakpoint();

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Partnerships Shopify"
        description="729 Solutions is a Shopify Plus partner. We can help build your project from start to finish or provide expertise where your team is lacking."
      />
      <Hero bgImage={patternHex}>
        <Hero.Inner>
          <Hero.Image
            src={shopifyPlusLogo}
            srcAlt={shopifyPlusWhiteLogo}
            alt="Shopify Plus logo"
          />
          <Hero.Content>
            <Hero.Title>
              Create amazing experiences on the Shopify platform
            </Hero.Title>
            <Hero.Description>
              {breakpoints.lg ? (
                <>
                  <p className="text-white">
                    E-commerce is booming- make sure your company keeps up
                  </p>
                  <p className="text-white">
                    We’re a Shopify Plus partner and can take on your entire
                    project, end-to-end, or just horsepower your team with
                    special skills.
                  </p>
                  <p className="text-white">
                    Design. Development. Project management. Release. Success.
                  </p>
                </>
              ) : null}
            </Hero.Description>
            <Hero.CallToAction>
              {breakpoints.lg ? (
                <CTA
                  text="Tell Us About Your Project"
                  className="btn btn-secondary-light"
                  href="/contact-us/"
                />
              ) : null}
            </Hero.CallToAction>
          </Hero.Content>
        </Hero.Inner>
      </Hero>
      {breakpoints.lg ? null : (
        <section className="bg-gray-100">
          <div className="container py-5">
            <div className="row">
              <div className="col text-center">
                <p className="heading-font-family-medium">
                  E-commerce is booming - make sure your company keeps up
                </p>
                <p className="heading-font-family-medium">
                  We’re a Shopify Plus partner and can take on your entire
                  project, end-to-end, or just horsepower your team with special
                  skills.
                </p>
                <p className="heading-font-family-medium mb-4">
                  Design. Development. Project management. Release. Success.
                </p>
                <CTA
                  text="Tell Us About Your Project"
                  className="btn btn-primary"
                  href="/contact-us/"
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <section>
        <div className="container py-6 px-5">
          <div
            className={classNames({
              clearfix: breakpoints.lg,
              row: !breakpoints.lg,
            })}
          >
            <div className="col-12 col-lg-6 float-lg-left mb-4">
              <div className="card custom-cards mb-0">
                <div className="card-body text-left p-4 p-md-5">
                  <h3
                    className={classNames({
                      h2: breakpoints.lg,
                    })}
                  >
                    Custom Themes
                  </h3>
                  <p>
                    Shopify is an exceptional product, and becomes even more
                    powerful and appealing to your customers when your brand
                    look, feel and voice shine through.
                  </p>
                  <p>
                    We can help you customize an existing theme or build an
                    exclusive one just for you.{" "}
                  </p>
                  <CTA
                    text="Find Out More"
                    className={classNames({
                      "btn btn-link btn-block text-left px-0": !breakpoints.sm,
                      "btn btn-primary": breakpoints.sm,
                    })}
                    href="/contact-us/"
                  />
                </div>
              </div>
            </div>
            <div className="col col-lg-6 float-lg-right mb-4">
              <div className="card custom-cards mb-0">
                <div className="card-body text-left p-4 p-md-5">
                  <h3
                    className={classNames({
                      h2: breakpoints.lg,
                    })}
                  >
                    Unique Applications
                  </h3>
                  <p>
                    Many brands have special workflows, shopping experiences,
                    unique business models, or feature sets that don’t come out
                    of the box.
                  </p>
                  <p>
                    We’ll help your brand create the additional functionality
                    you need to grow, manage your back end, and connect with
                    your consumers.
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <StaticImage
                      src="../images/partnerships-shopify/729-plus-gadget.png"
                      alt="729 plus Gadget = Faster, scalable custom apps"
                      width={320}
                    />
                  </div>
                  <p>
                    We’ve partnered with{" "}
                    <Link to="https://gadget.dev/" target="_blank">
                      Gadget.io
                    </Link>{" "}
                    the fastest growing Shopify app-building platform, to help
                    build more reliable, high- quality apps faster, without a
                    server, so you can scale your app in no time
                  </p>
                  <p>
                    Talk to us about how Gadget can help you rapidly build and
                    host your needs at a fraction of the cost.
                  </p>
                  <CTA
                    text="Let's Talk Apps"
                    className={classNames({
                      "btn btn-link btn-block text-left px-0": !breakpoints.sm,
                      "btn btn-primary": breakpoints.sm,
                    })}
                    href="/contact-us/"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 float-lg-left">
              <div className="card custom-cards mb-0">
                <div className="card-body text-left p-4 p-md-5">
                  <h3
                    className={classNames({
                      h2: breakpoints.lg,
                    })}
                  >
                    Systems Integrations
                  </h3>
                  <p>
                    For nearly a decade we’ve been connecting business tools. We
                    do this for over 30 leading SaaS partners and we specialize
                    in making Shopify work with any of your other software.
                  </p>
                  <p>
                    From on-prem legacy tools to the newest API driven
                    integrations, no one knows more than 729 about making your
                    business more efficient by wiring up all your tools and
                    making sure data flows accurately.
                  </p>
                  <CTA
                    text="What do you need to connect?"
                    className={classNames({
                      "btn btn-link btn-block text-left px-0": !breakpoints.sm,
                      "btn btn-primary": breakpoints.sm,
                    })}
                    href="/contact-us/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          background: "linear-gradient(#f7f7f7 0%, rgba(247, 247, 247, 0) 20%)",
        }}
      >
        <div className="container py-6 px-5">
          <div className="row">
            <div className="col-12 pb-4">
              <h2 className="text-center text-lg-left">
                Featured Shopify Plus Projects
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-12 mb-lg-5 pb-5">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <Link
                    to="https://www.ontechsmartservices.com/"
                    title="Smart Device Installation from the Experts – OnTech"
                    alt="Smart Device Installation from the Experts – OnTech"
                    target="_blank"
                  >
                    <div className="card shadow justify-content-center align-items-center mb-5">
                      <FeaturedOntechGraphic />
                    </div>
                  </Link>
                </div>
                <div className="col-12 col-lg-8 pl-lg-5">
                  <h3>Dish Networks</h3>
                  <p>
                    For Dish Networks, we integrated Shopify, Recurly and
                    Vertex.{" "}
                  </p>
                  <p>
                    The new platform allows customers to buy from an extensive
                    catalog of physical smart home based products and self
                    schedule installations.
                  </p>
                  <p>
                    There is complex payment functionality for combining
                    products in the cart, deals and ways to work with a service
                    technician.{" "}
                  </p>
                  <p>
                    We worked hand in hand with the Dish development team on
                    extremely short timelines to release.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-12">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <Link
                    to="https://shop.openfit.com/"
                    title="Openfit - Live And On Demand Fitness Classes"
                    alt="Openfit - Live And On Demand Fitness Classes"
                    target="_blank"
                  >
                    <div className="card shadow justify-content-center align-items-center mb-5">
                      <FeaturedOpenfitGraphic />
                    </div>
                  </Link>
                </div>
                <div className="col-12 col-lg-8 pl-lg-5">
                  <h3>Beachbody</h3>
                  <p>
                    For Beachbody, we built a custom e-commerce experience for
                    their new line of subscription products.{" "}
                  </p>
                  <p>
                    This was built to be a highly scalable, one-time, and
                    subscription-based sales platform. And it worked- 30,000 new
                    members signed up in the first month!{" "}
                  </p>
                  <p>
                    We provided project guidance, deep technical knowledge and
                    execution of a new custom shopify plus theme and
                    subscription product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary">
        <div className="container py-5 py-md-6">
          <div className="row">
            <div className="col-12 col-md-3 text-center mb-3">
              <SnapFingersIcon
                width={breakpoints.md ? 130 : 85}
                color="white"
              />
            </div>
            <div className="col-12 col-md-9">
              <h3
                className={classNames(
                  "text-center text-md-left text-white heading-font-family",
                  { h2: breakpoints.md }
                )}
              >
                Imagine Your Shopify Project...
              </h3>
              <p className="text-center text-md-left text-white">
                What will it mean to you:
              </p>
              <ul>
                <li className="heading-font-family text-white mb-2">
                  To have it done by a development company of reliable experts?
                </li>
                <li className="heading-font-family text-white mb-2">
                  To have it finished on time and on budget?
                </li>
                <li className="heading-font-family text-white mb-2">
                  To have it perform better than you thought possible?
                </li>
              </ul>
              <p className="text-center text-md-left text-white mb-5 px-2">
                Because that’s what you get when you work with us. Reach out
                today. If there’s a way, 729 can do it.
              </p>
              <div className="d-flex align-items-md-start justify-content-md-start justify-content-center">
                <CTA
                  href="/contact-us"
                  text="Let's Chat"
                  className="btn btn-white text-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
